
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
import { getFirestore } from 'firebase/firestore';
const firebaseConfig = {
  apiKey: "AIzaSyACbsm9jXTqRJjTbN9af-sYaSZk8awo9TU",
  authDomain: "techpeople-4030a.firebaseapp.com",
  projectId: "techpeople-4030a",
  storageBucket: "techpeople-4030a.appspot.com",
  messagingSenderId: "880031586662",
  appId: "1:880031586662:web:2cc451f0a5ede2e24e50a7",
  measurementId: "G-P130VZLSDP"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const auth = getAuth(app);
export const db = getFirestore(app);